import React from 'react';
import PropTypes from 'prop-types';
import css from './SectionCustomHero.module.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

if (typeof window !== 'undefined') {
  require('react-lazy-load-image-component/src/effects/opacity.css');
}

// SectionCustomHeroOld -> GERRY WEBER Style (Full screen image on landing page with text overlay)

const SectionCustomHero = ({ sectionId, svgImage, svgImageLR, title, text, blocks }) => {
  const button1 = blocks[0].callToAction;
  const button2 = blocks[1].callToAction;

  return (
    <div className={css['section-custom-hero-container']}>
      <div className={css['section-custom-hero']}>
        <LazyLoadImage
          alt="Hero"
          src={svgImage}
          placeholderSrc={svgImageLR}
          className={css['section-custom-hero-image']}
          effect="opacity"
          loading="eager"
          decoding="async"
          width="100%"
          threshold={100}
          height="auto"
          visibleByDefault={true}
        />
        <div className={css.darkOverlay}></div>
        <div className={css['overlay-content']}>
          <h1 className={css['overlay-title']}>{title}</h1>
          <p className={css['overlay-text']}>{text}</p>
          <div className={css['buttons-row']}>
            <a href={button1.href} target="_self">
              <button className={css['overlay-button']}>{button1.content}</button>
            </a>
            <a href={button2.href} target="_self">
              <button className={css['overlay-button']}>{button2.content}</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionCustomHero.propTypes = {
  sectionId: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  svgImage: PropTypes.string.isRequired,
  svgImageLR: PropTypes.string.isRequired,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      blockType: PropTypes.string.isRequired,
      blockId: PropTypes.string.isRequired,
      callToAction: PropTypes.shape({
        fieldType: PropTypes.string.isRequired,
        href: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};

export default SectionCustomHero;
